'use client';

import { useEffect, useState } from 'react';
import { ErrorComponent, ErrorOption } from '@/components/error';
import LinkInternal from '@/components/LinkInternal';
import messages from '@/components/templates/event-page/messages';
import NotFoundPage from '@/components/templates/not-found-page';
import ROUTES from '@/constants/routes';
import { getSlugs } from '@/utils/next/getSlugs';
type ErrorProps = {
  error: Error;
  reset: () => void;
};
export default function Error({
  error,
  reset
}: ErrorProps) {
  const {
    title,
    message,
    option_title,
    option_contact,
    option_refresh,
    option_wait
  } = messages.error;
  const [errorType, setErrorType] = useState<'unset' | 'not-found' | 'error'>('unset');
  const {
    pathname
  } = window?.location;
  useEffect(() => {
    const runAsync = async () => {
      const slugResponse = await getSlugs();
      if (!slugResponse.slugs.includes(pathname.split('/')[1])) {
        setErrorType('not-found');
      } else {
        setErrorType('error');
      }
    };
    runAsync();
  }, []);
  if (errorType === 'not-found') {
    return <NotFoundPage />;
  }
  if (errorType === 'error') {
    return <main>
        <div className="w-50 error-wrap">
          <ErrorComponent title={title} message={message} optionsTitle={option_title} isEventError error={error.message}>
            <ErrorOption icon="oa-restart">{option_refresh}</ErrorOption>
            <ErrorOption icon="oa-loader">{option_wait}</ErrorOption>
            <ErrorOption icon="oa-email">
              <LinkInternal to={`${ROUTES.contact}/?category=0&subject=${encodeURI(`Error on page ${pathname}`)}&message=${encodeURI(`An error occured on page ${pathname}\n\nDetails: ${error.message}`)}`}>
                {option_contact}
              </LinkInternal>
            </ErrorOption>
          </ErrorComponent>
        </div>
      </main>;
  }

  // errorType is not set yet
  return <></>;
}